import React from 'react';
import {navigate} from 'gatsby';
import {withFirebase} from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import '../../../pages/signin.css';

import {css} from '@emotion/core';
import googleIcon from '../../../images/icons/google-icon.svg';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInGoogleBase extends React.Component {
	constructor(props) {
		super(props);

		this.state = {error: null, isPhoneNumberVerified: false};
	}

	onSubmit = (event) => {
		let uid = null;
		this.props.firebase
			.doSignInWithGoogle()
			.then((socialAuthUser) => {
				if(socialAuthUser.user.phoneNumber != null) {
					this.setState({isPhoneNumberVerified: true});
				}
				// Create a user in your Firebase Realtime Database too
				// @TODO: Migrate to Firestore
				uid = socialAuthUser.user.uid;
				return this.props.firebase.user(socialAuthUser.user.uid).update({
					username: socialAuthUser.user.displayName,
					email: socialAuthUser.user.email,
					phoneNumber: socialAuthUser.user.phoneNumber,
					roles: {},
				});
			})
			.then(() => {
				this.setState({error: null});
				this.state.isPhoneNumberVerified ? navigate(ROUTES.HOME) : 
					navigate(ROUTES.MOBILE_VERFICATION);
			})
			.catch((error) => {
				if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
					error.message = ERROR_MSG_ACCOUNT_EXISTS;
				}

				this.setState({error});
			});

		event.preventDefault();
	};

	render() {
		const {error} = this.state;

		return (
			<form onSubmit={this.onSubmit}>
				<button className="buttonLink">
					<img className="iconCss" src={googleIcon} alt="phone icon" />
					<p className="buttonLinkText">Sign in with Google</p>
				</button>
				{error && <p>{error.message}</p>}
			</form>
		);
	}
}

const SiginWithGoogle = withFirebase(SignInGoogleBase);

export default SiginWithGoogle;
