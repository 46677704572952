import React from 'react';

import {Link} from 'gatsby';
import './placeholderColor.css';
import emailIcon from '../images/icons/email-icon.svg';
import SignInWithPhoneForm from '../components/SignIn/SiginWithMobile';
import './signin.css';
import SignInWithGoogle from '../components/SignIn/SiginWithGoogle';

export default () => (
	<div className="fullScreen">
        <div className="topBar">
            <div className="logoDiv">
				<Link to="/">
                	<span className="logo">Auto</span>
                	<span className="logo text-style-1">Save</span>
				</Link>
            </div>
        </div>
		<div className="signInScreen">
			<SignInWithPhoneForm type="signin" />
			<div className="buttonWrap phoneButtonWrap">
				<Link to="/signin">
					<button className="buttonLink">
						<img className="iconCss" src={emailIcon} alt="email icon" />
						<p className="buttonLinkText">Sign in with Email</p>
					</button>
				</Link>

				<SignInWithGoogle />
				<div className="bottomText newUserDiv">
					<span className="newUser">New user? </span>
					<Link className="aLink" to="/signup">
						<span className="newUser signUp">Sign Up</span>
					</Link>
				</div>
			</div>
		</div>
	</div>
);
